.footer {
  background-color: #60d19d;
  bottom: 0;
  color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  position: relative;
  text-align: center;
  width: 100%;
}

.footer .text {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer .name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.footer p {
  margin: 0;
  font-size: 14px;
}
