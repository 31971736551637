.sectionContainer {
  padding-left: 120px;
  padding-right: 120px;
  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* icon background styling */
.svgBackground {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 80vw;
  z-index: -999;
}

.svgIcon {
  opacity: 0.8;
  position: absolute;
  width: 50px;
}

/* hero section styling */
.heroSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-bottom: 40px;
  gap: 64px;
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
  }
}

.heroText {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

.heroTextHeader {
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  font-family: Popins, sans-serif;
}

.heroTextParagraph {
  text-align: left;
  font-size: 1.2rem;
  color: #757272;
  line-height: 1.6;
  font-family: Popins, sans-serif;
}

.heroImage {
  width: 60%;
  @media (max-width: 100px) {
    width: 100%;
  }
}

.heroImage img {
  width: 700px;
  height: auto;
  border: 2px solid black;
  border-radius: 300px;
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.heroButton {
  background-color: #60d19d;
  box-shadow: 0 0 10px rgba(1, 46, 9, 0.1);
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  border: none;
  padding: 20px;
  border-radius: 20px;
  color: white;
  font-family: Popins, sans-serif;
  transition: 0.3s ease;
}

.heroButton:hover {
  background-color: #2a9362;
}

/* info section styling */
.infoSection {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 20px;
  padding-bottom: 40px;
}

.infoCard {
  width: 180px;
  background-color: rgb(172, 223, 250);
  border-radius: 16px;
  box-shadow: 0 0 10px rgba(7, 1, 120, 0.1);
  font-family: Popins, sans-serif;
  padding: 8px;
}

/* service outline section styling */
.outlineSection {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 20px;
  padding-bottom: 40px;
  @media (max-width: 1200px) {
    overflow-x: scroll;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
}

.outlineCard {
  background-color: rgb(203, 230, 245);
  border-radius: 16px;
  width: 280px;
  padding: 16px;
  font-family: Popins, sans-serif;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(7, 1, 120, 0.1);
  @media (max-width: 1200px) {
    width: 200px;
  }
}

.outlineCardText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outlineCardHeader {
  padding: 0;
  margin-bottom: 0;
  inline-size: min-content;
}

.outlineCardParagraph {
  padding: 0;
  margin-bottom: 0;
}

/* logo section styling */
.logoContainer {
  padding: 20px;
  padding-bottom: 40px;
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(7, 1, 120, 0.1);
  overflow-x: scroll;
}

/* video section styling */
/* .videoSection {
  padding: 40px;
}

.videoSection img {
  box-shadow: 0 0 10px rgba(7, 1, 120, 0.1);
} */

/* form section styling */
.formSection {
  background-color: #c9e5fa;
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 10px;
}

.formSectionContent h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.formSectionContent p {
  align-items: center;
  color: #666;
  font-size: 16px;
  margin-bottom: 20px;
}

.consultationForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formGroup {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.formGroup label {
  color: #555;
  font-size: 14px;
  margin-bottom: 8px;
}

.formGroup input, .formGroup select {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  padding: 12px;
  transition: border-color 0.3s ease;
  width: 500px;
  @media (max-width: 768px) {
    width: 400px;
  }
}

.formGroup input:focus, .formGroup select:focus {
  border-color: #60d19d;
  outline: none;
}

.submitButton {
  background-color: #60d19d;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 12px 20px;
  transition: 0.3s ease;
  width: 500px;
  @media (max-width: 768px) {
    width: 400px;
  }
}

.submitButton:hover {
  background-color: rgb(42, 147, 98);
}

@media (max-width: 768px) {
  .formSection {
    padding: 30px;
  }
}
