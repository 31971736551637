.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 80px;
}

.contactButton {
  background-color: #ffffff;
  border: 2px solid #60d19d;
  border-radius: 24px;
  color: #000000;
  cursor: pointer;
  font-family: Popins, sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 16px;
}
